<template>
    <el-pagination background :layout="layout" :pager-count="pagerCount" :prev-text="prevText" :next-text="nextText"
        class="list-pagination" :page-size="pageSize" :page-count="pageCount" hide-on-single-page
        :total="pageCount > 0 ? null : total" :current-page="currentPage" @current-change="onPageChange">
        <span class="el-pagination__total">共{{ totalPage }}页</span>
    </el-pagination>
</template>

<script>
export default {
    data() {
        return {

        }
    },
    props: {
        pageSize: {
            type: Number,
            default: 10,
        },
        pageCount: {
            type: Number,
            default: 0,
        },
        total: {
            type: Number,
            default: 0,
        },
        currentPage: {
            type: Number,
            default: 0,
        },
    },
    emits: ['current-change'],
    computed: {
        totalPage: function () {
            return this.pageCount > 0 ? this.pageCount : Math.ceil(this.total / this.pageSize);
        },
        prevText: function () {
            if (window.screen.width < 720) {
                return '<'
            }
            return '上一页';
        },
        nextText: function () {
            if (window.screen.width < 720) {
                return '>'
            }
            return '下一页';
        },
        pagerCount: function () {
            if (window.screen.width < 720) {
                return 5;
            }
            return 7;
        },
        layout: function () {
            if (window.screen.width < 720) {
                //jumper
                return 'prev, next,jumper, ->, slot';
            }
            return 'prev, pager, next';
        }
    },
    methods: {
        onPageChange(pageindex) {
            this.$emit('current-change', pageindex);
            this.$emit('update:currentPage', pageindex)
        }
    },
}
</script>

<style lang="scss" scoped>
.total-page {
    color: #32C5FF;
}
</style>