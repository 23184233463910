import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)
import ExtConfig from '@/common/extconfig'

import Layout from '@/views/layout'

//解决菜单重复点击报错
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

//空视图
const Emptyview = {
    render(h) {
        return h('router-view')
    }
};

const routes = [
    {
        path: '/',
        redirect: '/main/index',
        hidden: true,
        meta: { title: '' }
    },
    {
        path: '/login',
        component: () => import('@/views/login'),
        meta: { title: '登录' }
    },
    {
        path: '/main',
        component: Layout,
        redirect: '/main/index',
        props: { screen: true },
        meta: { title: '主页' },
        children: [{
            path: 'index',
            name: 'index',
            component: () => import('@/views/main/index'),
            meta: { title: '首页' }
        }]
    },
    {
        path: '/danger',
        component: Layout,
        redirect: '/danger/index',
        meta: { title: '监测点管理' },
        children: [{
            path: 'index',
            name: 'danger-index',
            component: () => import('@/views/danger/index'),
            meta: { title: '' }
        }, {
            path: 'add',
            name: 'danger-add',
            component: () => import('@/views/danger/form'),
            props: { formtype: 'add' },
            meta: { title: '添加监测点', activeMenu: '/danger/index' }
        }, {
            path: 'edit',
            name: 'danger-edit',
            component: () => import('@/views/danger/form'),
            props: { formtype: 'edit' },
            meta: { title: '编辑监测点', activeMenu: '/danger/index' }
        }]
    },
    {
        path: '/monitor',
        component: Layout,
        redirect: '/monitor/index',
        meta: { title: '监测点位管理' },
        children: [{
            path: 'index',
            name: 'monitor-index',
            component: () => import('@/views/monitor/index'),
            meta: { title: '' }
        }, {
            path: 'add',
            name: 'monitor-add',
            component: () => import('@/views/monitor/form'),
            props: { formtype: 'add' },
            meta: { title: '添加监测点位', activeMenu: '/monitor/index' }
        }, {
            path: 'edit',
            name: 'monitor-edit',
            component: () => import('@/views/monitor/form'),
            props: { formtype: 'edit' },
            meta: { title: '编辑监测点位', activeMenu: '/monitor/index' }
        }]
    },
    {
        path: '/company',
        component: Layout,
        redirect: '/company/contractor',
        meta: { title: '' },
        children: [{
            path: 'contractor',
            name: 'contractor-contractor',
            component: () => import('@/views/company/contractor'),
            meta: { title: '承建单位' }
        }, {
            path: 'maintain',
            name: 'company-maintain',
            component: () => import('@/views/company/maintain'),
            meta: { title: '运维单位管理' }
        }, {
            path: 'maintainuser',
            name: 'company-maintainuser',
            component: Emptyview,
            meta: { title: '人员管理' },
            children: [{
                path: '',
                name: 'maintainuser-list',
                component: () => import('@/views/company/maintainUser'),
                props: (route) => ({ unitid: route.query.unitid }),
                meta: { title: '' },
            }, {
                path: 'add',
                name: 'maintainuser-add',
                component: () => import('@/views/company/maintainUserForm'),
                props: { formtype: 'add' },
                meta: { title: '添加人员', activeMenu: '/company/maintainuser' }
            }, {
                path: 'edit',
                name: 'maintainuser-edit',
                component: () => import('@/views/company/maintainUserForm'),
                props: { formtype: 'edit' },
                meta: { title: '编辑人员', activeMenu: '/company/maintainuser' }
            }]
        }]
    },
    {
        path: '/device',
        component: Layout,
        redirect: '/device/index',
        meta: { title: '监测设备' },
        children: [{
            path: 'index',
            name: 'device-index',
            component: () => import('@/views/device/index'),
            meta: { title: '设备管理' }
        }, {
            path: 'add',
            name: 'device-add',
            component: () => import('@/views/device/form'),
            props: { formtype: 'add' },
            meta: { title: '添加设备', activeMenu: '/device/index' }
        }, {
            path: 'edit',
            name: 'device-edit',
            component: () => import('@/views/device/form'),
            props: { formtype: 'edit' },
            meta: { title: '编辑设备', activeMenu: '/device/index' }
        }, {
            path: 'detail',
            name: 'device-detail',
            component: () => import('@/views/device/detail'),
            props: (route) => ({ detailid: route.query.id }),
            meta: { title: '设备详情', activeMenu: '/device/index' }
        }, {
            path: 'report',
            name: 'device-report',
            component: () => import('@/views/device/report'),
            meta: { title: '监测报告' }
        }, {
            path: 'inspect',
            name: 'device-inspect',
            component: () => import('@/views/device/inspect'),
            meta: { title: '巡查管理' }
        }, {
            path: 'command',
            name: 'device-command',
            component: () => import('@/views/device/command'),
            meta: { title: '命令下发' }
        }, {
            path: 'upgrade',
            name: 'device-upgrade',
            component: () => import('@/views/device/upgrade'),
            meta: { title: '在线升级' }
        }, {
            path: 'video',
            name: 'device-video',
            component: () => import('@/views/device/video'),
            meta: { title: '视频监控' }
        }, {
            path: 'curve',
            name: 'device-curve',
            component: () => import('@/views/device/curve'),
            meta: { title: '设备曲线' }
        }]
    },
    {
        path: '/warning',
        component: Layout,
        redirect: '/warning/index',
        meta: { title: '预警管理' },
        children: [{
            path: 'index',
            name: 'warning-index',
            component: () => import('@/views/warning/index'),
            meta: { title: '预警信息' }
        }, {
            path: 'model',
            name: 'warning-model',
            component: () => import('@/views/warning/model'),
            meta: { title: '预警模型' }
        }, {
            path: 'push',
            name: 'warning-push',
            component: () => import('@/views/warning/push'),
            meta: { title: '预警推送' }
        }]
    },
    {
        path: '/api',
        component: Layout,
        redirect: '/api/apitoken',
        meta: { title: '业务配置' },
        children: [{
            path: 'raydataconfig',
            name: 'raydata-config',
            component: () => import('@/views/api/raydataconfig'),
            meta: { title: '大屏设置' }
        }, {
            path: 'apitoken',
            name: 'api-apitoken',
            component: () => import('@/views/api/apitoken'),
            meta: { title: '开发接口' }
        }, {
            path: 'pushconfig',
            name: 'api-pushconfig',
            component: Emptyview,
            meta: { title: '推送配置' },
            children: [{
                path: '',
                name: 'api-pushlist',
                component: () => import('@/views/api/pushconfig'),
                meta: { title: '' }
            }, {
                path: 'device',
                name: 'api-pushdevice',
                component: () => import('@/views/api/pushdevice'),
                props: (route) => ({ detailid: route.query.detailid }),
                meta: { title: '添加推送配置', activeMenu: '/api/pushconfig' }
            }]
        }]
    },
    {
        path: '/system',
        component: Layout,
        redirect: '/system/syslog',
        meta: { title: '系统管理' },
        children: [{
            path: 'syslog',
            name: 'system-syslog',
            component: () => import('@/views/system/syslog'),
            meta: { title: '系统日志' }
        }, {
            path: 'sysinfo',
            name: 'system-sysinfo',
            component: () => import('@/views/system/sysinfo'),
            meta: { title: '系统信息' }
        }, {
            path: 'sysrole',
            name: 'system-sysrole',
            component: () => import('@/views/system/sysrole'),
            meta: { title: '角色管理' }
        }, {
            path: 'sysuser',
            name: 'system-sysuser',
            component: () => import('@/views/system/sysuser'),
            meta: { title: '用户管理' }
        }, {
            path: 'dictype',
            name: 'system-dictype',
            component: () => import('@/views/system/dictype'),
            meta: { title: '字典分类' }
        }, {
            path: 'dictionary',
            name: 'system-dictionary',
            component: () => import('@/views/system/dictionary'),
            meta: { title: '字典分类' }
        }, {
            path: 'sysmenu',
            name: 'system-sysmenu',
            component: () => import('@/views/system/sysmenu'),
            meta: { title: '菜单管理' }
        }]
    },
    {
        path: '/raydata',
        component: Emptyview,
        meta: { title: '大数据' },
        children: [{
            path: 'show',
            name: 'raydata-show',
            component: () => import('@/views/raydata/show'),
            meta: { title: '大数据展示平台', auth: false }
        }]
    }
]

const router = new VueRouter({
    mode: 'history',
    base: ExtConfig.getConfig('base', '/'),
    routes
})

export default router
