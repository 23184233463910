<template>
    <el-container class="layout-main">
        <l-aside :class="{ show: menuShow, hidden: !menuShow }" @menuSelect="onMenuSelect"></l-aside>
        <el-container>
            <el-header>
                <l-header @menuShow="onMenuShow"></l-header>
            </el-header>
            <el-main>
                <router-view v-if="screen" />
                <div class="app-container" v-else>
                    <l-breadcrumb></l-breadcrumb>
                    <div class="container-box">
                        <keep-alive :include="keepaliveinclude">
                            <router-view :key="$route.fullPath" />
                        </keep-alive>
                    </div>
                </div>
            </el-main>
        </el-container>
    </el-container>
</template>
<script>
import lAside from "./components/lAside";
import lHeader from "./components/lHeader";
import lBreadcrumb from "./components/lBreadcrumb";
import { mapGetters } from 'vuex'
export default {
    data() {
        return {
            menuShow: false,
        }
    },
    components: {
        lAside,
        lHeader,
        lBreadcrumb,
    },
    props: {
        screen: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        ...mapGetters(['keepaliveinclude'])
    },
    methods: {
        onMenuShow() {
            this.menuShow = true;
        },
        onMenuSelect() {
            this.menuShow = false;
        },
    },
};
</script>